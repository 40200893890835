
<template>
  <div>
    <v-menu >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" v-if="user_allowed" color="success" class="mx-2" small rounded  :disabled="disabled">
          <v-icon left>mdi-download </v-icon>
          exportar
        </v-btn>
      </template>
      <v-list>
        <v-list-item  @click="createDataExport('xlsx')" v-if="!hide_list.includes('xlsx')">
          <v-list-item-icon><v-icon small>mdi-file-excel </v-icon> </v-list-item-icon>
          <v-list-item-title>.XLSX</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/data_exports'" target="_blank">
          <v-list-item-icon><v-icon small>mdi-arrow-right-thick </v-icon> </v-list-item-icon>
          <v-list-item-title>Ver exportações</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="loading" persistent width="500px" transition="dialog-bottom-transition">
      <v-alert type="info" :value="true" class="my-0">
        Exportando...
        <v-progress-linear color='white' :indeterminate="true"></v-progress-linear>
      </v-alert>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="700px" transition="dialog-transition" >
      <v-card style="border-radius:12px">
        <v-card-title primary-title class="pa-3 pu2">
          <div class="headline mb-0">Exportação</div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='py-0'>
          <v-row>
            <v-col class="pt-6">
              {{ msg }}
            </v-col>
          </v-row>
          <v-btn :to="'/data_exports'" target="_blank" color="primary" small class="my-3">Ver exportações <v-icon right small>mdi-arrow-right</v-icon></v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn class="red" dark @click="dialog =! dialog"><v-icon left>mdi-close</v-icon>FECHAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Api from "@/api/index"

export default {
  name: "BtnCreateDataExport",
  props: {
    date_obj: {
      type: Object,
    },
    api_model: {
      type: String,
    },
    action:{
      type: String,
      default: '',
      required: true
    },
    filters: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hide_list: {
      type: Array,
      default: () => []
    },
    file_name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      msg: '',
    }
  },
  methods: {
    sendAlert(){
      let confirmation = confirm("O limite máximo de exportação é de 200 itens. \n\nAjuste os filtros de exibição para uma quantidade igual ou inferior a 200 itens antes de prosseguir com a exportação.")
      return confirmation
    },
    createDataExport(format){
      this.loading = true
      let export_data = {
        // user_id: this.current_user.id,
        status: 'requested',    // t.integer :
        klass: this.api_model,    // t.string :
        action: this.action,    // t.string :
        file_name: this.file_name, 
        params: { ...this.date_obj ,  ...this.filters, format: format},    // t.jsonb :
      }
      let msg = ''
      Api.DataExport.create(export_data).then(r => {
        msg = 'Solicitação realizada, acesse a seção de exportações para baixar o arquivo.'
        this.addNotification({type: 'success', msg: msg})
        this.msg = msg
      }).catch(err =>{
        console.log(err.response.data.error)
        this.addNotification({type: 'error', msg: `Ops, ocorreu um erro...`})
        this.msg = 'Ocorreu um erro, a exportação não pode ser realizada.'
      }).finally(() =>{
        this.dialog = true
        this.loading = false
      })
    }, 
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  computed: {
    user_allowed() {
      return ["super_admin", "admin", "director"].includes(this.current_user.role.name)
    },
    ...mapState({
      current_user: state=> state.User.user
    }),
  },
}
</script>

<style scoped>

</style>
